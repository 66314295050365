import { useParams } from '@tanstack/react-router'
import { type ReactNode } from 'react'
import { trpcReact } from '../../../../client'
import { assert } from '../../../../lib/errors'
import { UsersListContent } from '../common/backoffice-users/users-list-content'

export default function PartnersUsersPage(): ReactNode {
  const { organization } = useParams({ from: '/partner/$organization/users/' })

  assert(organization, 'Organization is required')

  const usersCall = trpcReact.users.partnerListUsers.useQuery(organization)

  return <UsersListContent listUsers={usersCall.data} type="partner" />
}
